import { useState, useEffect } from 'react'; 
import './weather.scss'
import '../icons/style.css';


function Weather (props) {
    const [numDay, setNumDay] = useState('1');
    const [activeDay, setActiveDay] = useState({});
    const [loading, setLoading] = useState(true);
    const [numActiveCard, setNumActiveCard] = useState("1");
    const data = props.dataWeather
    const getNumDay = new Date(data.forecast.forecastday[2].date).getDay();
    let day; 
    let dayMain; 

    switch (getNumDay) {
        case 0: 
            day = 'Недiля';
            dayMain = 'Недiлю';
            break; 
        case 1: 
            day = 'Понеділок';
            dayMain = 'Понеділок';
            break; 
        case 2: 
            day = 'Вівторок';
            dayMain = 'Вівторок';
            break; 
        case 3: 
            day = 'Середа';
            dayMain = 'Середу';
            break; 
        case 4: 
            day = 'Четвер';
            dayMain = 'Четвер';
            break; 
        case 5: 
            day = "П'ятниця";
            dayMain = "П'ятницю";
            break; 
        case 6: 
            day = 'Субота';
            dayMain = 'Суботу';
            break; 
        
    }
    
    function numDaySpark(e) {
        setNumDay(numDay => numDay = e.target.closest('.day').getAttribute('name'))
    }

    function renderDay() {
        const dayOne = {
            name: data.location.name,
            iconUrl: data.current.condition.icon,
            conditionText: data.current.condition.text,
            term: data.current.temp_c,
            termMin: data.forecast.forecastday[0].day.mintemp_c,
            termMax: data.forecast.forecastday[0].day.maxtemp_c,
            wind: data.current.wind_kph,
            pressure: data.current.pressure_mb,
            feelslike: data.current.feelslike_c,
            humidity: data.current.humidity,
            forecast: data.forecast.forecastday[0].day.daily_chance_of_rain + "%",
            hoursData: [
                {
                    time: data.forecast.forecastday[0].hour[0].time,
                    icon: data.forecast.forecastday[0].hour[0].condition.icon,
                    term: data.forecast.forecastday[0].hour[0].temp_c,
                    wind: data.forecast.forecastday[0].hour[0].wind_kph,
                    pressure: data.forecast.forecastday[0].hour[0].pressure_mb,
                    feelslike: data.forecast.forecastday[0].hour[0].feelslike_c, 
                    humidity: data.forecast.forecastday[0].hour[0].humidity,
                    forecast: data.forecast.forecastday[0].hour[0].chance_of_rain
                },
                {
                    time: data.forecast.forecastday[0].hour[3].time,
                    icon: data.forecast.forecastday[0].hour[3].condition.icon,
                    term: data.forecast.forecastday[0].hour[3].temp_c,
                    wind: data.forecast.forecastday[0].hour[3].wind_kph,
                    pressure: data.forecast.forecastday[0].hour[3].pressure_mb,
                    feelslike: data.forecast.forecastday[0].hour[3].feelslike_c, 
                    humidity: data.forecast.forecastday[0].hour[3].humidity,
                    forecast: data.forecast.forecastday[0].hour[3].chance_of_rain
                },
                {
                    time: data.forecast.forecastday[0].hour[6].time,
                    icon: data.forecast.forecastday[0].hour[6].condition.icon,
                    term: data.forecast.forecastday[0].hour[6].temp_c,
                    wind: data.forecast.forecastday[0].hour[6].wind_kph,
                    pressure: data.forecast.forecastday[0].hour[6].pressure_mb,
                    feelslike: data.forecast.forecastday[0].hour[6].feelslike_c, 
                    humidity: data.forecast.forecastday[0].hour[6].humidity,
                    forecast: data.forecast.forecastday[0].hour[6].chance_of_rain
                },
                {
                    time: data.forecast.forecastday[0].hour[9].time,
                    icon: data.forecast.forecastday[0].hour[9].condition.icon,
                    term: data.forecast.forecastday[0].hour[9].temp_c,
                    wind: data.forecast.forecastday[0].hour[9].wind_kph,
                    pressure: data.forecast.forecastday[0].hour[9].pressure_mb,
                    feelslike: data.forecast.forecastday[0].hour[9].feelslike_c, 
                    humidity: data.forecast.forecastday[0].hour[9].humidity,
                    forecast: data.forecast.forecastday[0].hour[9].chance_of_rain
                },
                {
                    time: data.forecast.forecastday[0].hour[12].time,
                    icon: data.forecast.forecastday[0].hour[12].condition.icon,
                    term: data.forecast.forecastday[0].hour[12].temp_c,
                    wind: data.forecast.forecastday[0].hour[12].wind_kph,
                    pressure: data.forecast.forecastday[0].hour[12].pressure_mb,
                    feelslike: data.forecast.forecastday[0].hour[12].feelslike_c, 
                    humidity: data.forecast.forecastday[0].hour[12].humidity,
                    forecast: data.forecast.forecastday[0].hour[12].chance_of_rain
                },
                {
                    time: data.forecast.forecastday[0].hour[15].time,
                    icon: data.forecast.forecastday[0].hour[15].condition.icon,
                    term: data.forecast.forecastday[0].hour[15].temp_c,
                    wind: data.forecast.forecastday[0].hour[15].wind_kph,
                    pressure: data.forecast.forecastday[0].hour[15].pressure_mb,
                    feelslike: data.forecast.forecastday[0].hour[15].feelslike_c, 
                    humidity: data.forecast.forecastday[0].hour[15].humidity,
                    forecast: data.forecast.forecastday[0].hour[15].chance_of_rain
                },
                {
                    time: data.forecast.forecastday[0].hour[18].time,
                    icon: data.forecast.forecastday[0].hour[18].condition.icon,
                    term: data.forecast.forecastday[0].hour[18].temp_c,
                    wind: data.forecast.forecastday[0].hour[18].wind_kph,
                    pressure: data.forecast.forecastday[0].hour[18].pressure_mb,
                    feelslike: data.forecast.forecastday[0].hour[18].feelslike_c, 
                    humidity: data.forecast.forecastday[0].hour[18].humidity,
                    forecast: data.forecast.forecastday[0].hour[18].chance_of_rain
                },
                {
                    time: data.forecast.forecastday[0].hour[21].time,
                    icon: data.forecast.forecastday[0].hour[21].condition.icon,
                    term: data.forecast.forecastday[0].hour[21].temp_c,
                    wind: data.forecast.forecastday[0].hour[21].wind_kph,
                    pressure: data.forecast.forecastday[0].hour[21].pressure_mb,
                    feelslike: data.forecast.forecastday[0].hour[21].feelslike_c, 
                    humidity: data.forecast.forecastday[0].hour[21].humidity,
                    forecast: data.forecast.forecastday[0].hour[21].chance_of_rain
                },
            ]
        };
        const dayTwo  = {
            name: data.location.name,
            iconUrl: data.forecast.forecastday[1].day.condition.icon,
            conditionText: data.forecast.forecastday[1].day.condition.text,
            term: data.forecast.forecastday[1].day.avgtemp_c,
            termMin: data.forecast.forecastday[1].day.mintemp_c,
            termMax: data.forecast.forecastday[1].day.maxtemp_c,
            wind: data.forecast.forecastday[1].day.maxwind_kph,
            pressure: 'Не має даних',
            feelslike: 'Немає даних',
            humidity: 'Немає даних',
            forecast: data.forecast.forecastday[1].day.daily_will_it_rain + "%",
            hoursData: [
                {
                    time: data.forecast.forecastday[1].hour[0].time,
                    icon: data.forecast.forecastday[1].hour[0].condition.icon,
                    term: data.forecast.forecastday[1].hour[0].temp_c,
                    wind: data.forecast.forecastday[1].hour[0].wind_kph,
                    pressure: data.forecast.forecastday[1].hour[0].pressure_mb,
                    feelslike: data.forecast.forecastday[1].hour[0].feelslike_c, 
                    humidity: data.forecast.forecastday[1].hour[0].humidity,
                    forecast: data.forecast.forecastday[1].hour[0].chance_of_rain
                },
                {
                    time: data.forecast.forecastday[1].hour[3].time,
                    icon: data.forecast.forecastday[1].hour[3].condition.icon,
                    term: data.forecast.forecastday[1].hour[3].temp_c,
                    wind: data.forecast.forecastday[1].hour[3].wind_kph,
                    pressure: data.forecast.forecastday[1].hour[3].pressure_mb,
                    feelslike: data.forecast.forecastday[1].hour[3].feelslike_c, 
                    humidity: data.forecast.forecastday[1].hour[3].humidity,
                    forecast: data.forecast.forecastday[1].hour[3].chance_of_rain
                },
                {
                    time: data.forecast.forecastday[1].hour[6].time,
                    icon: data.forecast.forecastday[1].hour[6].condition.icon,
                    term: data.forecast.forecastday[1].hour[6].temp_c,
                    wind: data.forecast.forecastday[1].hour[6].wind_kph,
                    pressure: data.forecast.forecastday[1].hour[6].pressure_mb,
                    feelslike: data.forecast.forecastday[1].hour[6].feelslike_c, 
                    humidity: data.forecast.forecastday[1].hour[6].humidity,
                    forecast: data.forecast.forecastday[1].hour[6].chance_of_rain
                },
                {
                    time: data.forecast.forecastday[1].hour[9].time,
                    icon: data.forecast.forecastday[1].hour[9].condition.icon,
                    term: data.forecast.forecastday[1].hour[9].temp_c,
                    wind: data.forecast.forecastday[1].hour[9].wind_kph,
                    pressure: data.forecast.forecastday[1].hour[9].pressure_mb,
                    feelslike: data.forecast.forecastday[1].hour[9].feelslike_c, 
                    humidity: data.forecast.forecastday[1].hour[9].humidity,
                    forecast: data.forecast.forecastday[1].hour[9].chance_of_rain
                },
                {
                    time: data.forecast.forecastday[1].hour[12].time,
                    icon: data.forecast.forecastday[1].hour[12].condition.icon,
                    term: data.forecast.forecastday[1].hour[12].temp_c,
                    wind: data.forecast.forecastday[1].hour[12].wind_kph,
                    pressure: data.forecast.forecastday[1].hour[12].pressure_mb,
                    feelslike: data.forecast.forecastday[1].hour[12].feelslike_c, 
                    humidity: data.forecast.forecastday[1].hour[12].humidity,
                    forecast: data.forecast.forecastday[1].hour[12].chance_of_rain
                },
                {
                    time: data.forecast.forecastday[1].hour[15].time,
                    icon: data.forecast.forecastday[1].hour[15].condition.icon,
                    term: data.forecast.forecastday[1].hour[15].temp_c,
                    wind: data.forecast.forecastday[1].hour[15].wind_kph,
                    pressure: data.forecast.forecastday[1].hour[15].pressure_mb,
                    feelslike: data.forecast.forecastday[1].hour[15].feelslike_c, 
                    humidity: data.forecast.forecastday[1].hour[15].humidity,
                    forecast: data.forecast.forecastday[1].hour[15].chance_of_rain
                },
                {
                    time: data.forecast.forecastday[1].hour[18].time,
                    icon: data.forecast.forecastday[1].hour[18].condition.icon,
                    term: data.forecast.forecastday[1].hour[18].temp_c,
                    wind: data.forecast.forecastday[1].hour[18].wind_kph,
                    pressure: data.forecast.forecastday[1].hour[18].pressure_mb,
                    feelslike: data.forecast.forecastday[1].hour[18].feelslike_c, 
                    humidity: data.forecast.forecastday[1].hour[18].humidity,
                    forecast: data.forecast.forecastday[1].hour[18].chance_of_rain
                },
                {
                    time: data.forecast.forecastday[1].hour[21].time,
                    icon: data.forecast.forecastday[1].hour[21].condition.icon,
                    term: data.forecast.forecastday[1].hour[21].temp_c,
                    wind: data.forecast.forecastday[1].hour[21].wind_kph,
                    pressure: data.forecast.forecastday[1].hour[21].pressure_mb,
                    feelslike: data.forecast.forecastday[1].hour[21].feelslike_c, 
                    humidity: data.forecast.forecastday[1].hour[21].humidity,
                    forecast: data.forecast.forecastday[1].hour[21].chance_of_rain
                },
            ]
        };
        const dayThree = {
            name: data.location.name,
            iconUrl: data.forecast.forecastday[2].day.condition.icon,
            conditionText: data.forecast.forecastday[2].day.condition.text,
            term: data.forecast.forecastday[2].day.avgtemp_c,
            termMin: data.forecast.forecastday[2].day.mintemp_c,
            termMax: data.forecast.forecastday[2].day.maxtemp_c,
            wind: data.forecast.forecastday[2].day.maxwind_kph,
            pressure: 'Не має даних',
            feelslike: 'Немає даних',
            humidity: 'Немає даних',
            forecast: data.forecast.forecastday[2].day.daily_will_it_rain + "%",
            hoursData: [
                {
                    time: data.forecast.forecastday[2].hour[0].time,
                    icon: data.forecast.forecastday[2].hour[0].condition.icon,
                    term: data.forecast.forecastday[2].hour[0].temp_c,
                    wind: data.forecast.forecastday[2].hour[0].wind_kph,
                    pressure: data.forecast.forecastday[2].hour[0].pressure_mb,
                    feelslike: data.forecast.forecastday[2].hour[0].feelslike_c, 
                    humidity: data.forecast.forecastday[2].hour[0].humidity,
                    forecast: data.forecast.forecastday[2].hour[0].chance_of_rain
                },
                {
                    time: data.forecast.forecastday[2].hour[3].time,
                    icon: data.forecast.forecastday[2].hour[3].condition.icon,
                    term: data.forecast.forecastday[2].hour[3].temp_c,
                    wind: data.forecast.forecastday[2].hour[3].wind_kph,
                    pressure: data.forecast.forecastday[2].hour[3].pressure_mb,
                    feelslike: data.forecast.forecastday[2].hour[3].feelslike_c, 
                    humidity: data.forecast.forecastday[2].hour[3].humidity,
                    forecast: data.forecast.forecastday[2].hour[3].chance_of_rain
                },
                {
                    time: data.forecast.forecastday[2].hour[6].time,
                    icon: data.forecast.forecastday[2].hour[6].condition.icon,
                    term: data.forecast.forecastday[2].hour[6].temp_c,
                    wind: data.forecast.forecastday[2].hour[6].wind_kph,
                    pressure: data.forecast.forecastday[2].hour[6].pressure_mb,
                    feelslike: data.forecast.forecastday[2].hour[6].feelslike_c, 
                    humidity: data.forecast.forecastday[2].hour[6].humidity,
                    forecast: data.forecast.forecastday[2].hour[6].chance_of_rain
                },
                {
                    time: data.forecast.forecastday[2].hour[9].time,
                    icon: data.forecast.forecastday[2].hour[9].condition.icon,
                    term: data.forecast.forecastday[2].hour[9].temp_c,
                    wind: data.forecast.forecastday[2].hour[9].wind_kph,
                    pressure: data.forecast.forecastday[2].hour[9].pressure_mb,
                    feelslike: data.forecast.forecastday[2].hour[9].feelslike_c, 
                    humidity: data.forecast.forecastday[2].hour[9].humidity,
                    forecast: data.forecast.forecastday[2].hour[9].chance_of_rain
                },
                {
                    time: data.forecast.forecastday[2].hour[12].time,
                    icon: data.forecast.forecastday[2].hour[12].condition.icon,
                    term: data.forecast.forecastday[2].hour[12].temp_c,
                    wind: data.forecast.forecastday[2].hour[12].wind_kph,
                    pressure: data.forecast.forecastday[2].hour[12].pressure_mb,
                    feelslike: data.forecast.forecastday[2].hour[12].feelslike_c, 
                    humidity: data.forecast.forecastday[2].hour[12].humidity,
                    forecast: data.forecast.forecastday[2].hour[12].chance_of_rain
                },
                {
                    time: data.forecast.forecastday[2].hour[15].time,
                    icon: data.forecast.forecastday[2].hour[15].condition.icon,
                    term: data.forecast.forecastday[2].hour[15].temp_c,
                    wind: data.forecast.forecastday[2].hour[15].wind_kph,
                    pressure: data.forecast.forecastday[2].hour[15].pressure_mb,
                    feelslike: data.forecast.forecastday[2].hour[15].feelslike_c, 
                    humidity: data.forecast.forecastday[2].hour[15].humidity,
                    forecast: data.forecast.forecastday[2].hour[15].chance_of_rain
                },
                {
                    time: data.forecast.forecastday[2].hour[18].time,
                    icon: data.forecast.forecastday[2].hour[18].condition.icon,
                    term: data.forecast.forecastday[2].hour[18].temp_c,
                    wind: data.forecast.forecastday[2].hour[18].wind_kph,
                    pressure: data.forecast.forecastday[2].hour[18].pressure_mb,
                    feelslike: data.forecast.forecastday[2].hour[18].feelslike_c, 
                    humidity: data.forecast.forecastday[2].hour[18].humidity,
                    forecast: data.forecast.forecastday[2].hour[18].chance_of_rain
                },
                {
                    time: data.forecast.forecastday[2].hour[21].time,
                    icon: data.forecast.forecastday[2].hour[21].condition.icon,
                    term: data.forecast.forecastday[2].hour[21].temp_c,
                    wind: data.forecast.forecastday[2].hour[21].wind_kph,
                    pressure: data.forecast.forecastday[2].hour[21].pressure_mb,
                    feelslike: data.forecast.forecastday[2].hour[21].feelslike_c, 
                    humidity: data.forecast.forecastday[2].hour[21].humidity,
                    forecast: data.forecast.forecastday[2].hour[21].chance_of_rain
                },
            ]
        };

        switch (numDay) {
            case "1":
                setActiveDay(dayOne);
                break; 
            case "2": 
                setActiveDay(dayTwo);
                break; 
            case "3": 
                setActiveDay(dayThree);
                break; 
            default: 
                setActiveDay({})
        }
    }

    useEffect(() => {
        renderDay()
        
    },[numDay])
    useEffect(() => {
        setLoading(false)
    },[activeDay])

    function classActiveCard(e) {
        setNumActiveCard(e.target.closest('.info-days__card').getAttribute('name'));
        const block = document.querySelector('.info-days__info')
        block.classList.toggle('active');
    }
    console.log(numActiveCard)

    const hoursCard = loading ? null:  activeDay.hoursData.map((item, i) => {
                return (
                    <div onClick={(e) => {classActiveCard(e)}} key={i} name={i} className="info-days__card">
                        <div className="info-days__card-time">{item.time.slice(-5)}</div>
                        <img src={item.icon} alt="" className="info-days__card-img" />
                        <div className="info-days__card-term">{item.term > 0 ? '+' + item.term : item.term}&deg;</div>
                            <div className="info-days__info-item">
                                <div className="info-days__info-icon icon-umbrella"></div>
                                <div className="info-days__info-text">{item.forecast}</div>
                                <div className="info-days__info-descr"></div>
                            </div>
                        <div className='info-days__info'>
                            <div className="info-days__info-item">
                                <div className="info-days__info-icon icon-wind1"></div>
                                <div className="info-days__info-text">{item.wind}</div>
                                <div className="info-days__info-descr"></div>
                            </div>
                            <div className="info-days__info-item">
                                <div className="info-days__info-icon icon-barometer"></div>
                                <div className="info-days__info-text">{item.pressure}</div>
                                <div className="info-days__info-descr"></div>
                            </div>
                            <div className="info-days__info-item">
                                <div className="info-days__info-icon icon-accessibility"></div>
                                <div className="info-days__info-text">{item.feelslike}</div>
                                <div className="info-days__info-descr"></div>
                            </div>
                            <div className="info-days__info-item">
                                <div className="info-days__info-icon icon-droplet"></div>
                                <div className="info-days__info-text">{item.humidity}</div>
                                <div className="info-days__info-descr"></div>
                            </div>
                            
                        </div>
                    </div>
                )
            })
    // const carlsDay = loading ? null :  hoursCard;
    console.log(activeDay.hoursData)

    const classDay1 = numDay === '1' ? "weather__days-1 day active" : "weather__days-1 day";
    const classDay2 = numDay === '2' ? "weather__days-2 day active" : "weather__days-2 day";
    const classDay3 = numDay === '3' ? "weather__days-3 day active" : "weather__days-3 day";
    return (
        <>
            <div className="weather">
                <div className="weather__text-info">
                    <div className="weather__text-info-img icon-location"></div>
                    <div className="weather__text-info-text"><span>{activeDay.name}</span> - погода на {numDay === '1' ? 'сьогодні' : numDay === '2' ? 'завтра' : dayMain }</div>
                </div>
                <div className="weather__wrapper">

                    <div className="weather__days">
                        <div onClick={(e) => numDaySpark(e)} name="1" className={classDay1}>
                            <div className="day__name">Сьогодні</div>
                            <img src={data.current.condition.icon} className="day__img"></img>
                            <div className="day__term">
                                <div className="day__term-min">{data.forecast.forecastday[0].day.mintemp_c > 0 ? '+' + data.forecast.forecastday[0].day.mintemp_c : data.forecast.forecastday[0].day.mintemp_c}&deg;</div>
                                /
                                <div className="day__term-max">{data.forecast.forecastday[0].day.maxtemp_c > 0 ? '+' + data.forecast.forecastday[0].day.maxtemp_c : data.forecast.forecastday[0].day.maxtemp_c}&deg;</div>
                            </div>
                            <div className="day__descr">{data.current.condition.text}</div>
                        </div>
                        <div onClick={(e) => numDaySpark(e)} name="2" className={classDay2}>
                            <div className="day__name">Завтра</div>
                            <img src={data.forecast.forecastday[1].day.condition.icon} className="day__img"></img>
                            <div className="day__term">
                            <div className="day__term-min">{data.forecast.forecastday[1].day.mintemp_c > 0 ? '+' + data.forecast.forecastday[1].day.mintemp_c :  data.forecast.forecastday[1].day.mintemp_c}&deg;</div>
                                /
                                <div className="day__term-max">{data.forecast.forecastday[1].day.maxtemp_c > 0 ? '+' + data.forecast.forecastday[1].day.maxtemp_c : data.forecast.forecastday[1].day.maxtemp_c}&deg;</div>
                            </div>
                            <div className="day__descr">{data.forecast.forecastday[1].day.condition.text}</div>
                        </div>
                        <div onClick={(e) => numDaySpark(e)} name="3" className={classDay3}>
                            <div className="day__name">{day}</div>
                            <img src={data.forecast.forecastday[2].day.condition.icon} className="day__img"></img>
                            <div className="day__term">
                            <div className="day__term-min">{data.forecast.forecastday[2].day.mintemp_c > 0 ? '+' + data.forecast.forecastday[2].day.mintemp_c :  data.forecast.forecastday[2].day.mintemp_c}&deg;</div>
                                /
                                <div className="day__term-max">{data.forecast.forecastday[2].day.maxtemp_c > 0 ? '+' + data.forecast.forecastday[2].day.maxtemp_c :  data.forecast.forecastday[2].day.maxtemp_c}&deg;</div>
                            </div>
                            <div className="day__descr">{data.forecast.forecastday[2].day.condition.text}</div>
                        </div>
                    </div>

                    <div className="weather__info">
                        <div className="weather__info_img">
                            <img src={activeDay.iconUrl} alt="weather info" className="" />
                            <span>{activeDay.conditionText}</span>
                        </div>
                        <div className="weather__info-term">
                            <div className="weather__info-current">{activeDay.term > 0 ? '+' + activeDay.term : activeDay.term}&deg;</div>
                            <div className="weather__info-max_min">
                                <div className="weather__info-min">{activeDay.termMin > 0 ? '+' + activeDay.termMin : activeDay.termMin}&deg;</div>
                                /
                                <div className="weather__info-max">{activeDay.termMax > 0 ? '+' + activeDay.termMax : activeDay.termMax}&deg;</div>
                            </div>
                        </div>
                        <div className="weather__info-part part">
                            <div className="part__wind flex hover__weat-detal">
                                <div className="part__wind-icon icon-wind1"></div>
                                <div className="part__wind-text"> {activeDay.wind}mph</div>
                                <div className="part__wind-descr">Вітер</div>
                            </div>
                            <div className="part__pressure flex hover__weat-detal">
                                <div className="part__pressure-icon icon-barometer"></div>
                                <div className="part__pressure-text">{activeDay.pressure}</div>
                                <div className="part__pressure-descr">Тиск</div>
                            </div>
                            <div className="part__feelslike flex hover__weat-detal">
                                <div className="part__feelslike-icon icon-accessibility"></div>
                                <div className="part__feelslike-text">{activeDay.feelslike > 0 ? '+' + activeDay.feelslike : activeDay.feelslike}&deg;</div>
                                <div className="part__feelslike-descr">Відчувається</div>
                            </div>
                            <div className="part__humidity flex hover__weat-detal">
                                <div className="part__humidity-icon icon-droplet"></div>
                                <div className="part__humidity-text">{activeDay.humidity}</div>
                                <div className="part__humidity-descr">Вологість</div>
                            </div>
                            <div className="part__humidity flex hover__weat-detal">
                                <div className="part__humidity-icon icon-umbrella"></div>
                                <div className="part__humidity-text">{activeDay.forecast} </div>
                                <div className="part__humidity-descr">Вірогідність дощу</div>
                            </div>
                        </div>
                    </div>
                    <div className="weather__info-days info-days">
                        <div className="info-days__wrapper">
                            {hoursCard}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Weather