import './search.scss';
import '../icons/style.css';
import Request from '../../services/request';
import nameSityUa from '../../data/UaSity.json'
import infoIcon from '../icons/info_black_24dp.svg'
import close from '../icons/close.svg'
import { useState, useEffect} from 'react'; 

function Search (props) {
    const request = new Request();
    const [infoModalActive, setInfoModalActive] = useState(false); // Стан активності модального вікна інформації
    const [searchData, setSearchData] = useState([]); //Статичні відфільтровані дані міст з пошуку
    const [curentName, setCurentName] = useState(''); // Поточна назва пошуку
    const [erorr, setError] = useState(false); //Стан помилки
    const [smtAndM, setSmtAndM] = useState('м. '); //Стейт вибору - місто м. або Селище міського типу
    const [loading, setLoading] = useState(true); // статус загрузки
    const [searchValue, setSearchValue] = useState(''); //Поле вводу пошуку
  
    const data = nameSityUa; //Статичні дані міст України
  
    function installType (e) {
      if(e) {
        if(e.target.name === 'SMT') {
          setSmtAndM('смт ')
        }else if (e.target.name === "M") {
          setSmtAndM('м. ')
        }else {
          setSmtAndM('')
        }
      }
    }
  
    function setSearch (e) { // Використовується в input
        setSearchValue(e.target.value)
    }
    function setData (data) {
      request.getSearch(data)
      .then(res => props.setData(res))
      .then(res => {
        setLoading(false)
        setError(false)
      })
      .catch(eror => setError(true))
    }
    function setDataCeth (data) {
      request.getSearchCeth(data)
      .then(res => props.setData(res))
      .then(res => {
        setLoading(false)
        setError(false)
      })
      .catch(eror => setError(true))
    }
  
    function inputFilter (type, value) {
      return data.filter(item => {
        return item.SettlementUkr.toUpperCase().indexOf((type + value).toUpperCase()) > -1;
      })
    }
  
    useEffect(() => {
      setData('Хотин'); //Визов запросу Погоди
    }, [])
    
    useEffect(() => {
      if(erorr) {
        setDataCeth(curentName)
      }
    }, [erorr])

    
    useEffect(() => {
      if((searchValue.length) === 0 ) {
        setSearchData([])
      }else {
        setSearchData(inputFilter(smtAndM, searchValue))
      }
    }, [searchValue])
  
  
    function sityNameSearch () {
     return searchData.map((item, i) => {
        return (
          <>
            <li onClick={() => {setSearchValue(''); setCurentName(item.SettlementEng); setData(item.SettlementRus); props.setLoading(true) }} key={i}>{item.SettlementUkr}</li>
          </>
        )
      })
    }
    function onCastomName(value) {
      setData(searchValue); 
      props.setLoading(true); 
      setSearchValue('');
    }
    function modalActive () {
      setInfoModalActive(infoModalActive => !infoModalActive)
    }
  
    const elem = sityNameSearch();
  
    const styleBtnType = {
      backgroundColor: '#73B1D6',
      border: 'none'
    }
    const widthInput = { //Розширяємий Imput
        width: (searchValue.length + 1) * 9 + "px"
    }
    const classActive = searchValue.length === 0 ? "search__result" : "search__result active";
    const lenghtSitySearch = searchData.length === 0 ? 'Не знайдено' : `Знайдено" ${searchData.length} "населених пунктів`

    function blockMainInfo() {
      if(props.loading) {
       return null
      }else {
        const info = {
          nameSity: props.dataWeather.location.name,
          nameRegion: props.dataWeather.location.region,
          nameTimezones: props.dataWeather.location.tz_id,
          lat: props.dataWeather.location.lat,
          lon: props.dataWeather.location.lon
        }
        const classActiveInfo = !infoModalActive ? 'info' : 'info active';
        return (
          <div onClick={() => modalActive ()} className={classActiveInfo}>
            <div className="info__location">
              <div className="info__location_wrapper">
                <div className="info__location_region">
                  <div className="info__location_title">Location</div>
                  <div className="info__location_name-sity">Місто: <span className='info__location-text'>{info.nameSity}</span></div>
                  <div className="info__location_name-region">Регіон: <span className='info__location-text'>{info.nameRegion}</span></div>
                  <div className="info__location_name-timezones">Timezones: <span className='info__location-text'>{info.nameTimezones}</span></div>
                </div>
                <div className="info__location_coordinate">
                  <div className="info__location_coordinate-title">Координати</div>
                  <div className="info__location_coordinate-lat">Широта: <span className='info__location-text'>{info.lat}</span></div>
                  <div className="info__location_coordinate-lon">Довгота: <span className='info__location-text'>{info.lon}</span></div>
                </div>
                
              </div>
            </div>
          </div>
        )
      }
      
    }

    
    return (
        <div className="App">
            <div className="container">
            {blockMainInfo()}
                <div className="search">
                  <div className="search__button-type">
                    <button style={smtAndM === 'м. ' ? styleBtnType: null} onClick={e => {installType(e)}} name="M" className="search__M-btn btn">М</button>
                    <button style={smtAndM === 'смт ' ? styleBtnType: null} onClick={e => {installType(e)}} name="SMT" className="search__SMT-btn btn">СМТ</button>
                    <div className="search__button-text_active">{smtAndM === 'смт ' ? "Пошук по Селещам міського типу": "Пошук по містам"}</div>
                  </div>
                  <div className="search__imput-wrapper">
                    <div onClick={() => {onCastomName()}} className="search__input-img icon-search"/>
                    <div className="search__imput-wrapper_wr">
                        <input value={searchValue} onKeyDown={(e) => e.code == 'Enter' ? onCastomName() : null} onChange={e => {setSearch(e)} } type="text" className="search__input"/>
                        <div className="search__input-info_icon">
                          <img onClick={() => modalActive ()} src={infoIcon} alt="" />
                        </div>
                        <div className={classActive}>
                            <ul>
                                <li className="search__result-lenght"> {lenghtSitySearch}</li>
                                {elem}
                            </ul>
                        </div>
                    
                    </div>
                  </div>
                </div>
            </div>
    
         
        </div>
      );
}
export default Search; 