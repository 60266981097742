

class Request {

   _key = "0305377433634faea84101413220704";

    async getResourse (url) {
      let res = await fetch(url);
  
        if(!res.ok) {
            throw new Error(`Cloud not fetch ${url}, status: ${res.status}`);
        };
        return await res.json();
    }
  
    getSearch(name, day) {
      return this.getResourse(`http://api.weatherapi.com/v1/forecast.json?key=${this._key}&q=${name}&days=3&aqi=no&alerts=no&lang=ru`)
    }
    getSearchCeth(name, day) {
      return this.getResourse(`http://api.weatherapi.com/v1/forecast.json?key=${this._key}&q=${name}&days=3&aqi=no&alerts=no&lang=ru`)
    }
}

export default Request;