import Search from './components/search/search';
import Weather from './components/weather/weather';
import Spinner from './components/spiner/Spinner';
import './fonts/fonts.css';

import './App.scss';
import { useState, useEffect} from 'react';

function App(props) {
  const [dataWeather, setDataWeather] = useState();
  const [loading, setLoading] = useState(true);


  function setData(data) {
    setDataWeather(data)
    setLoading(false)
  }  


  useEffect(() => {
    if(loading === false) {
      console.log(dataWeather)
    }
  }, [loading])

  const content = !loading ? <Weather loading={loading} dataWeather={dataWeather}/> : null;
  const spiner = loading ? <Spinner/> : null;
  return (
    <div className="App">
      <div className="container">
        <Search dataWeather={dataWeather} setLoading={setLoading} loading={loading} setData={setData}/>
        {spiner}
        {content}
      </div>
    </div>
  );
}

export default App;
