import img from './Rhombus.gif';
import './spiner.css'

const Spinner = () => {

    return (
        <div className="img__spinner-wrapper">
            <img src={img} className="img-spinner" alt="" />
        </div>
    )
}

export default Spinner; 